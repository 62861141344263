.header-footer-page-container{
    min-height: calc(100vh - 118px); // footer height
    display: flex;
    flex-direction: column;
   // justify-content: space-between;

    .brand-logo-container {
        padding: 11px 32px;
        border-bottom: 0.5px solid rgb(225, 225, 225);
    }

    .loader {
        height: calc(100vh - 200px);
        .sweet-loading {
            margin: 0;
        }
    }

    .main-content {
      .logo-text {
        h6 {
          margin: 0 0 8px 0;
        }
      }
    }
}
