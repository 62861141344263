@mixin common-styles {
  h5 {
    color: var(--du-color-primary-aquamarine);
  }
  path {
    fill: var(--du-color-primary-aquamarine);
  }
}

.home-side-nav-container {
  .side-nav-option {
    display: flex;
    gap: 10px;
    height: 40px;
    align-items: center;
    padding-left: 32px;
    cursor: pointer;

    h5 {
      padding-top: 5px;
    }

    &:hover {
      @include common-styles;
    }
  }

  .side-nav-menu-active {
    background: var(--du-color-primary-pale);
    border-right: 3px solid var(--du-color-primary-aquamarine);
    @include common-styles;
  }

  .side-nav-menu-folder-list {
    max-height: calc(100vh - 340px);
    overflow-y: auto;
    margin-top: -12px;

    .folder-list-active {
      background: var(--du-color-primary-pale);
      border-right: 3px solid var(--du-color-primary-aquamarine);
    }

    li {
      cursor: pointer;
      padding: 10px 0;
      text-decoration: none;
      align-items: center;
      padding-left: 54px;

      .folder-name-container {
        width: calc(100% - 32px);
      }

      &:hover {
        color: var(--du-color-primary-aquamarine);
      }
    }

    .li-active {
      background: var(--du-color-primary-pale);
      border-right: 3px solid var(--du-color-primary-aquamarine);
    }
  }
}
