.fs-10 {
  font-size: 10px;
  line-height: 12px;
}

.fs-12 {
  font-size: 12px;
  line-height: 14px;
}

.fs-14 {
  font-size: 14px;
  line-height: 18px;
}

.fs-16 {
  font-size: 16px;
  line-height: 20px;
}

.fs-18 {
  font-size: 18px;
  line-height: 22px;
}

.fs-20 {
  font-size: 20px;
  line-height: 24px;
}

.fs-24 {
  font-size: 24px;
  line-height: 28px;
}

.fs-32 {
  font-size: 32px;
  line-height: 36px;
}

.fs-48 {
  font-size: 48px;
  line-height: 52px;
}

.fs-64 {
  font-size: 64px;
  line-height: 68px;
}
